/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ContentPageTemplate from "../../templates/content-page";
const MDXLayout = ContentPageTemplate;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Verhaltenstherapie"), "\n", React.createElement(_components.p, null, "Die ", React.createElement(_components.strong, null, "Verhaltenstherapie"), " ist ein psychotherapeutisches Behandlungsverfahren, welches über inzwischen einige Jahrzehnte immer weiterentwickelt und ausdifferenziert wurde und eine ganze Reihe von Methoden umfasst. Auch hier erfolgt in der Regel eine Behandlungssitzung pro Woche. Gearbeitet wird jeweils an klar benannten Problemlagen, für die ganz lebensnah Lösungen gesucht werden, indem das eigene Verhalten, die eigene Gedankenwelt, die Gefühle und Körperreaktionen in Bezug auf die jeweilige Situation herausgearbeitet werden, so dass man sich allmählich besser versteht und mit Hilfe von Übungen andere Verhaltens- und Erlebensweisen erproben kann und so zu besseren Lösungen kommt."), "\n", React.createElement(_components.p, null, "Die Verhaltenstherapie kommt bei einer ganzen Reihe von psychischen Erkrankungen zum Einsatz, darunter z. B. Depressionen, Ängste, besonders Phobien, Zwänge, aber auch Traumafolgestörungen."), "\n", React.createElement(_components.p, null, "Die Verhaltenstherapie erfolgt als Kurzzeittherapie (bis 25 Sitzungen) oder als Langzeittherapie (bis 45, in besonderen Situationen bis 60, maximal 80 Sitzungen)"));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
